import styled from "styled-components"
import { flex } from "../styles/partials"
import Navbar from "../components/Navbar"
import Flowers from '../resources/flowers.webp'
import GuestList from "../components/GuestList"

const Guests = () => {
    return (
        <>
        <Navbar guestsPage />
        <GuestList />
        <ImageContainer className='flowers'>
            <img src={Flowers} />
        </ImageContainer>
        </>
    )
}

const ImageContainer = styled.div`
  ${flex('column', 'flex-end', 'center')}
  position: sticky;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  z-index: 15;
  pointer-events: none;

  img {
    width: clamp(800px, 100%, 1200px);
  }
`

export default Guests