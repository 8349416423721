//CSS
import styled from 'styled-components'
import { colors, flex, fonts } from '../styles/partials'
import { FullPageSection, Title, } from '../styles/styled-components'
//react hooks
import { useState } from 'react'
// components
import RSVP from './RSVP'
import Question from './Question'
//context
import { useContext } from 'react';
import AppContext from '../context/AppContext'

const ChooseType = styled.fieldset`
    padding: 12px;
    border-radius: 4px;

    div {
        ${flex('row', 'center', 'center')};
        gap: 20px;

        >div {
            ${flex()};
            gap: 10px;
        }
    }

    legend {
        font-size: ${fonts.paragraph};
        padding: 0px 10px;
    }

    label {
        font-size: ${fonts.paragraph};
    }
`
const ContactFieldset = styled.fieldset`
    padding: 12px;
    width: clamp(200px, 100%, 600px);
    border-radius: 4px;

    legend {
        font-size: ${fonts.paragraph};
        padding: 0px 10px;
    }

    label {
        font-size: ${fonts.paragraph};
    }
`

const Contact = () => {

    const [typeOfForm, setTypeOfForm] = useState('RSVP')
    const {db} = useContext(AppContext)

    const handleForm = (e) => {
        setTypeOfForm(e.target.value)
    }

    return (
        <FullPageSection id='contact'>
            {db && (
            <>
            <Title>{db.contact.title}</Title>
            <ChooseType>
                <legend>{db.contact.options.title}</legend>
                <div>
                    <div>
                        <label htmlFor="rsvp">{db.contact.options.rsvp}</label>
                        <input onChange={(e) => handleForm(e)} type="radio" name="type" id="rsvp" defaultChecked defaultValue={'RSVP'} />
                    </div>
                    <div>
                        <label htmlFor="question">{db.contact.options.question}</label>
                        <input onChange={(e) => handleForm(e)} type="radio" name="type" id="question" defaultValue={'Question'} />
                    </div>
                </div>
            </ChooseType>
            <ContactFieldset>
                <legend>{typeOfForm === 'RSVP' ? db.contact.options.rsvp : db.contact.options.question}</legend>
                {typeOfForm === 'RSVP' ? <RSVP /> : <Question />}
            </ContactFieldset>
            </>
            )}
        </FullPageSection>
    )
}

export default Contact