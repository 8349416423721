//CSS
import styled from 'styled-components'
import { colors, flex, fonts } from '../styles/partials'
import { Title, FullPageSection } from '../styles/styled-components'
//context
import AppContext from '../context/AppContext'
import { useContext, useState } from 'react'

const InnerContainer = styled.div`
    ${flex('column', 'flex-start', 'center')};
    width: 100%;
`
const Table = styled.table`
    gap: 10px;
`
const TimeSlot = styled.td`
    width: 60px;
    font-size: ${fonts.paragraph};
    padding: 10px;
`
const Event = styled.td`
    padding: 10px;
    font-size: ${fonts.paragraph};
`
const Row = styled.tr`
        ${flex()};
        gap: 20px;
`

const OnTheDay = () => {

    const {db} = useContext(AppContext)

    return (
        <FullPageSection id='on-the-day'>
            {db && (
            <>
            <Title>{db.on_the_day.title}</Title>
            <InnerContainer>
                <Table>
                    <tbody>
                    <Row>
                        <TimeSlot>15:00</TimeSlot>
                        <Event>{db.on_the_day.ceremony}</Event>
                    </Row>
                    <Row>
                        <TimeSlot>16:30</TimeSlot>
                        <Event>{db.on_the_day.cocktail}</Event>
                    </Row>
                    <Row>
                        <TimeSlot>18:00</TimeSlot>
                        <Event>{db.on_the_day.dinner}</Event>
                    </Row>
                    {/* <Row>
                        <TimeSlot>22:00</TimeSlot>
                        <Event>{db.on_the_day.photo}</Event>
                    </Row> */}
                    <Row>
                        <TimeSlot>22:30</TimeSlot>
                        <Event>{db.on_the_day.dance}</Event>
                    </Row>
                    <Row>
                        <TimeSlot>00:00</TimeSlot>
                        <Event>{db.on_the_day.snacks}</Event>
                    </Row>
                    <Row>
                        <TimeSlot>02:00</TimeSlot>
                        <Event>{db.on_the_day.ending}</Event>
                    </Row>
                    </tbody>
                </Table>
            </InnerContainer>
            </>
            )}
        </FullPageSection>
    )
}

export default OnTheDay