import {css} from "styled-components";

export const colors = {
    main: '#F6F1EB',
    secondary: '#897057',
    green: '#7CB683',
}

export const flex = (direction, justify, align) => css`
    display: flex;
    flex-direction: ${direction || 'row'};
    justify-content: ${justify || 'flex-start'};
    align-items: ${align || 'flex-start'};
`

export const fonts = {
    heading: 'clamp(20px, 2.4em, 50px)',
    subheading: 'clamp(16px, 2em, 30px)',
    paragraph: 'clamp(12px, 1.2em, 18px)',
}