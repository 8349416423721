import styled from "styled-components"
import { useState } from "react"
import { colors, fonts } from "../styles/partials"
//context
import AppContext from '../context/AppContext'
import { useContext } from 'react'

const GuestSearch = ({guests}) => {

    const {db} = useContext(AppContext)
    const [namesMatching, setNamesMatching] = useState([])

    const getNamesMatchingSearch = (e) => {
        if (e.target.value === ''){
            setNamesMatching([])
        } else {
            const matching = guests.filter(guest => guest.name.toLowerCase().includes(e.target.value))
            setNamesMatching(matching)
        }
    }

    const handleClick = (id) => {
        const element = document.getElementById(id)
        const searchBar = document.getElementById("search-bar")
        element.scrollIntoView()
        searchBar.value = ''
        setNamesMatching([])
    }

    return (
        <Container>
        {db && (
        <>
        <Form autoComplete="off">
            <input
            autoCapitalize='none'  
            type="text" 
            placeholder={db.guestSearch}
            id="search-bar"
            onChange={(e) => getNamesMatchingSearch(e)}/>
        </Form>
        <List>
            {namesMatching.map((name, index) => {
                const nameArray = name.name.split('')
                const filtered = nameArray.filter(symbol => symbol !== ' ')
                const id = filtered.join('')
                return (
                    <ListItem key={index} onClick={() => handleClick(id)}>{name.name}</ListItem>
                )
            })}
        </List>
        </>
        )}
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: center;
    position: sticky;
    /* position: relative; */
    top: 60px;
    background-color: ${colors.main};
`
const Form = styled.form`
    width: 280px;

    input {
        width: 100%;
        padding: 8px 16px;
    }
`
const List = styled.ul`
    width: 280px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 60px;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;

    a {
        width: 100%;
        text-decoration: none;
    }
`
const ListItem = styled.li`
    width: 100%;
    padding: 8px;
    font-size: ${fonts.paragraph};
    cursor: pointer;


    &:hover {
        background-color: #fafafa;
    }

`

export default GuestSearch