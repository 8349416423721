import styled from "styled-components"
import { flex } from "../styles/partials"
import { Title, SubTitle, IntroText } from "../styles/styled-components"
//context
import AppContext from '../context/AppContext'
import { useContext } from 'react'
//
import GuestSearch from "./GuestSearch"

const GuestList = () => {

    const {db, language} = useContext(AppContext)

    return (
        <Container>
            {db && (
                <>
                <Title>{db.weddingGuests}</Title>
                <GuestSearch guests={db.guests} />
                <List>
                {db.guests.map((guest, index) => {
                    const nameArray = guest.name.split('')
                    const filtered = nameArray.filter(symbol => symbol !== ' ')
                    const id = filtered.join('')
                    return (
                        <li key={index} id={id}>
                        <SubTitle bold>{guest.name}</SubTitle>
                        <IntroText>{guest.description}</IntroText>
                        </li>
                    )
                })}
                </List>
                </>
            )}
        </Container>
    )
}

const List = styled.ul`
    ${flex('column', 'center', 'center')};
    text-align: center;
    gap: 16px;

    >li {
        ${flex('column', 'center', 'center')}; 
        scroll-margin-top: 140px;
    }
`
const Container = styled.section`
    ${flex('column', 'flex-start', 'center')};
    min-height: 100vh;
    gap: 20px;
    width: 100%;
    max-width: 1000px;
    padding: 80px 20px;
`

export default GuestList