import { guestsEnglish } from "./guests-eng"
import { guestsSwedish } from "./guests-sve"

export const english = {
    where_and_when: {
        title: 'where & when',
        intro: "Our wedding will take place on August 5 th in Älvkarleby, which is conveniently located between Edsbyn and the Stockholm area where the majority of our guests will be traveling from.",
        ceremony: "The wedding ceremony will be held in Älvkarleby Church at 3pm.",
        reception: "Our wedding reception will take place at Officersmässen on Laxön. Officersmässen is an impressive building beautifully located by the Dalälven river. If you’re really lucky, you can spot real, live salmon jumping around in the river. The first guest to capture a nice photo of a real, live salmon will win a cash prize of 100kr from the bride. The bride, and the bride only, will decide if your photo is nice enough to claim the generous cash prize. Good luck!"
    },
    on_the_day: {
        title: 'on the day',
        ceremony: 'Wedding ceremony in Älvkarleby Church',
        cocktail: 'Toast for the newlyweds at Officersmässen',
        dinner: 'Dinner, drinks and toasts',
        photo: 'Group photography',
        dance: 'Esther and Vidar open the dancefloor',
        snacks: 'Midnight snacks',
        ending: 'Party ends'
    },
    accomodation: {
        title: 'accomodation',
        first: "Accomodation close to Officersmässen is limited. For those who are open to something simple and possibly sharing a room with one or more people, the Officers Villas are a stone's throw away from Officersmässen. It is a hostel with a few single and double rooms, but mostly four and six-bed rooms where guests have access to shared bathrooms and common kitchen and living areas. You can take a closer look at this accommodation ",
        second: "Both villas are booked but unfortunately there aren’t enough rooms to house all of our guests. If you would like to stay there and are open to sharing a room, please let us know and we will do our best to fit you in. The price will depend on how many guests choose to stay in the villas but is likely to be around 400-700 SEK per person/night, depending on if you need to rent linen etc.",
        third: "If this option doesn’t suit you, the closest town is Gävle, which is about a 30 minute car or taxi ride away. When we know more about who is staying where, we are positive that shared rides can be sorted out for those of you who will be travelling home in the late hours.",
        options: "Some accomodation options in Gävle:"
    },
    info: {
        title: 'information',
        childrenTitle: 'children',
        children: "We would like our wedding to be child-free but do not want your children to be the only reason you cannot come. If your children are very young or cannot be away from you for other reasons, contact us and we’ll work something out.",
        giftTitle: 'gifts',
        gift: "If you would like to celebrate our day with a gift, we would really appreciate a contribution towards out honeymoon. Our apartment is tiny and we already have all the types of gadgets and appliances we could possibly need, so we would rather not receive any material gifts. Tor Sinclair is the only guest who is exempt from this wish as he, for religious reasons, must gift us a really expensive bowl or vase.",
        parkingTitle: 'parking',
        parking: "For those of you arriving with cars, there is plenty of parking next to the church. There is also parking right next to the reception venue, as well as next to the Officers villas. Just follow the signs that you’ll see when you arrive at Laxön.",
        speechesTitle: 'speeches etc.',
        speeches: "If you would like to lead a sing-along, give a speech or perform a really, really cool dance, please contact our MC’s Sarah and Carita. Send an email to sarahbitamazire@gmail.com and let them know what you’re planning to do. To guarantee your 15 minutes of fame, please attach an audition video with references from any earlier performances you’ve been a part of. Just kidding. The references aren’t necessary.",
    },
    gallery: {
        title: 'gallery'
    },
    contact: {
        title: 'contact / RSVP',
        options: {
            title: 'I want to',
            rsvp: 'RSVP',
            question: 'Ask a question'
        },
        rsvp: {
            title: 'RSVP',
            name: "Write the names of all the people you wants to RSVP for",
            email: 'E-mail',
            attending: {
                title: 'Will you be attending?',
                yes: 'Yes',
                no: 'No',
                how_many: 'How many people?',
                name_second: 'Name of second person'
            },
            message: 'Message',
            placeholder: 'Tell us for example if you have any allergies or which song you want to hear on the dancefloor.',
            button: 'Submit'
        },
        question: {
            title: 'Question',
            name: 'Name',
            email: 'E-mail',
            question: 'Your question here',
            button: 'Submit'
        }
    },
    weddingGuests: "Guests at our wedding",
    guestSearch: 'Search for a guest',
    guests: guestsEnglish
}

//

export const swedish = {
    where_and_when: {
        title: 'var & när',
        intro: 'Vi gifter oss den 5 augusti i Älvkarleby, som lämpligt nog är beläget mittemellan Edsbyn och Stockholmsområdet där majoriteten av våra gäster kommer att resa från.',
        ceremony: "Vigseln äger rum kl. 15.00 i Älvkarleby kyrka.",
        reception: "Bröllopsfesten kommer att hållas på Officersmässen på Laxön. Den imponerande byggnaden är vackert belägen alldeles intill Dalälven, där man kan se livs levande laxar om man har riktig tur. Första gästen som fångar ett foto på en livs levande lax i Dalälven får 100kr av bruden. OBS! Bruden och endast bruden bestämmer om fotot är fint nog för att du ska tilldelas prispengarna. Lycka till!"
    },
    on_the_day: {
        title: 'på dagen',
        ceremony: 'Vigsel i Älvkarleby kyrka',
        cocktail: 'Brudskål utanför Officersmässen',
        dinner: 'Bröllopsmiddag med allt som hör till',
        photo: 'Gruppfotografering',
        dance: 'Esther och Vidar drar igång dansgolvet',
        snacks: 'Vickning',
        ending: 'Festen avslutas'
    },
    accomodation: {
        title: 'boende',
        first: "Boende nära Officersmässen är begränsat. För de som är öppna för något enkelt och möjligen att dela rum med en eller flera personer är Officersvillorna bara ett stenkast från Officersmässen. Det är ett vandrarhem med några enkel- och dubbelrum, men mestadels fyra- och sexbäddsrum där gästerna har tillgång till delade badrum och gemensamma kök- och vardagsrum. Du kan ta en närmare titt på detta boende ",
        second: "Vi har bokat båda villorna, men tyvärr finns det inte tillräckligt med rum för att rymma alla våra gäster. Om du vill bo där och är öppen för att dela rum, säg till så ska vi göra vårt bästa för att ordna det. Priset beror på hur många gäster som väljer att bo i villorna, men kommer troligen att vara cirka 400-700 SEK per person/natt, beroende på om du behöver hyra lakan osv.",
        third: "Om detta alternativ inte passar dig, är den närmaste staden Gävle, som ligger cirka 30 minuter bort med bil eller taxi. När vi vet mer om vilka som bor var är vi säkra på att delade resor kan ordnas för er som kommer att resa hem sent på kvällen.",
        options: "Några alternativ på boende i Gävle:"
    },
    info: {
        title: 'information',
        childrenTitle: 'barn',
        children: "Vi önskar ha ett barnfritt bröllop men vill inte att era barn ska vara den enda anledningen till att ni inte kan komma. Om era barn är pyttesmå eller inte kan vara ifrån er av andra anledningar, skriv till oss så löser vi något tillsammans.",
        giftTitle: 'gåvor',
        gift: "Vill ni uppmärksamma vår dag med en gåva skulle vi verkligen uppskatta ett bidrag till vår bröllopsresa. Mot bakgrunden att vi bor på ynka 34kvm och redan har alla möjliga slags prylar så undanbedes materiella gåvor. Denna önskan gäller alla gäster förutom Tor Sinclair som av religiösa skäl måste ge oss en riktigt dyr skål eller vas.",
        parkingTitle: 'parkering',
        parking: "För er som kommer med bil finns det gott om parkering vid kyrkan. Precis intill festlokalen finns det också parkering, samt uppe vid Officersvillorna. Följ skyltarna som ni kommer se när ni svänger in på Laxön.",
        speechesTitle: 'tal och uppträdanden',
        speeches: "För er som vill leda en allsång, hålla ett tal eller framföra en riktigt, riktigt cool dans så anmäls detta till våra toastmasters Sarah och Carita. Skicka ett mail till sarahbitamazire@gmail.com och för att öka era chanser att faktiskt få lite scentid så kan ni bifoga en auditionvideo samt referenser från tidigare uppträdanden. Skoja. Eller?",
    },
    gallery: {
        title: 'galleri'
    },
    contact: {
        title: 'kontakt / OSA',
        options: {
            title: 'Jag vill',
            rsvp: 'OSA',
            question: 'Ställa en fråga'
        },
        rsvp: {
            title: 'OSA',
            name: "Vem OSA'r du för?",
            email: 'E-mail',
            attending: {
                title: 'Kommer ni på bröllopet?',
                yes: 'Ja',
                no: 'Nej',
                how_many: 'Hur många?',
                name_second: 'Namn på andra personen',
            },
            message: 'Meddelande',
            placeholder: 'Berätta till exempel om du har några allergier eller vilken låt du vill höra på dansgolvet.',
            button: 'Skicka'
        },
        question: {
            title: 'Fråga',
            name: 'Namn',
            email: 'E-mail',
            question: 'Din fråga här',
            button: 'Skicka'
        }
    },
    weddingGuests: "Gäster på bröllopet",
    guestSearch: 'Sök efter en gäst',
    guests: guestsSwedish
}

