import styled from "styled-components"
import { flex } from "../styles/partials"
import Header from "../components/Header"
import Navbar from "../components/Navbar"
import Where from "../components/Where"
import OnTheDay from "../components/OnTheDay"
import Accomodation from "../components/Accomodation"
import Info from "../components/Info"
import Gallery from "../components/Gallery"
import Contact from "../components/Contact"
import Flowers from '../resources/flowers.webp'


const Home = () => {
    return (
        <>
        <Header />
        <Navbar />
        <Where />
        <OnTheDay />
        <Accomodation />
        <Info />
        <Gallery />
        {/* <Contact /> */}
        <ImageContainer className='flowers'>
            <img src={Flowers} />
        </ImageContainer>
        </>
    )
}

const ImageContainer = styled.div`
  ${flex('column', 'flex-end', 'center')}
  position: sticky;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  z-index: 15;
  pointer-events: none;

  img {
    width: clamp(800px, 100%, 1200px);
  }
`

export default Home