import { Title, FullPageSection, IntroText, SubTitle } from '../styles/styled-components'
//context
import AppContext from '../context/AppContext'
import { useContext } from 'react'

const Info = () => {

    const {db, language} = useContext(AppContext)

    return (
        <FullPageSection id='information'>
            {db && (
                <>
                <Title>{db.info.title}</Title>
                <SubTitle>{db.info.childrenTitle}</SubTitle>
                <IntroText>{db.info.children}</IntroText>  
                <SubTitle>{db.info.giftTitle}</SubTitle>
                <IntroText>{db.info.gift}</IntroText>   
                <SubTitle>{db.info.parkingTitle}</SubTitle>                     
                <IntroText>{db.info.parking}</IntroText>
                <SubTitle>{db.info.speechesTitle}</SubTitle>
                <IntroText>{db.info.speeches}</IntroText>
                </>
            )}
        </FullPageSection>
    )
}

export default Info