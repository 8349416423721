//CSS
import styled, {css, keyframes} from 'styled-components'
import { colors, flex, fonts } from '../styles/partials'

export const Title = styled.h2`
    font-weight: 300;
    font-size: ${fonts.heading};
    margin-top: 40px;
    color: ${colors.secondary};
`
export const SubTitle = styled.h3`
    font-weight: 300;
    font-size: ${fonts.subheading};
    color: ${colors.secondary};
    margin-bottom: -16px;

    ${props => props.bold && css`
       font-weight: 600;
       margin-bottom: 0px;
    `}
`
export const FullPageSection = styled.section`
    ${flex('column', 'flex-start', 'center')};
    gap: 20px;
    width: 100%;
    max-width: 1000px;
    padding: 20px;
`
export const InputAndLabel = styled.div`
    width: 100%;
    gap: 6px;

    input[type='text'],input[type='email'], textarea {
        width: 100%;
    }

    label, input {
        font-size: ${fonts.paragraph};
    }

    input, textarea {
        padding: 8px;
    }

    ${props => props.row && css`
        ${flex('row', 'flex-start', 'center')};
    `}
    ${props => props.column && css`
        ${flex('column')};
    `}
`
export const Form = styled.form`
    width: 100%;
    font-size: ${fonts.paragraph};
    ${flex('column')};
    gap: 12px;
`
export const SubmitButton = styled.button` 
    border-radius: 4px;
    align-self: center;
    ${flex('row', 'center', 'center')};
    width: 200px;
    padding: 10px;
    background-color: ${colors.secondary};
    color: white;
    border: none;

    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

    &:hover {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }
`
const SpinAnimation = keyframes`
    0% {transform: rotate(0deg)}
    50% {transform: rotate(180deg)}
    100% {transform: rotate(360deg)}
`
 export const Spinner = styled.div`
    align-self: center;
    height: 24px;
    width: 24px;
    border: 3px solid white;
    border-radius: 50%;
    border-top-color: transparent;

    animation: linear 1s ${SpinAnimation} infinite;
`
export const IntroText = styled.p`
    font-size: ${fonts.paragraph};
    text-align: center;
    padding: 8px;
`