export const guestsEnglish = [
    {
        name: 'Elsie-Marie Einarsson',
        description: "Mother of the bride. Originally from Hälsingland but her heart belongs to East Africa where she has spent a large part of her life. If you're interested in a safari, she is more than happy to help organize the trip!"
    }, 
    {
        name: 'Julius Bitamazire',
        description: "Father of the bride. Even at his ripe age of 64 years, he still claims to be 40. Refuses to drink cold water and firmly believes that the main purpose of ketchup is to cool down the food that it is put on."
    }, 
    {
        name: 'Agnes Bitamazire',
        description: "Wife of the bride&#39;s father. A ray of sunshine who danced on FaceTime when we called to tell her that we were engaged. We hope to catch a glimpse of those dance moves later tonight!"
    }, 
    {
        name: 'Jon-Erik Rehn',
        description: "Father of the groom. Took the Swedish university entrance exam a few years ago just for fun. The result was three incorrect answers until it turned out that it was actually the answer key that was wrong, not Jon-Erik. So yes, you can ask him about absolutely anything! Go for it!"
    }, 
    {
        name: 'Harriet Rehn',
        description: "Mother of the groom. Traveled to Tanzania for the first time when she was seven years old and has been allergic to the Nordic winter ever since. A pro at everyday life, parties, and inseminating cows."
    }, 
    {
        name: 'Inger Söder',
        description: "The brides grandmother. Studies have shown that it is impossible to leave her home without having been fed a whole tray of cakes and without having been given at least two handwoven tea towels."
    }, 
    {
        name: 'Ellert Söder',
        description: 'husband to the bride’s grandmother. Secretly got married to Inger 13 years ago and by doing so, he gained 16 grandchildren in a day.'
    }, 
    {
        name: 'Mea Rehn',
        description: "The groom’s grandmother and the obvious Matriarch of the Rehn family. Has her own jewelry collection made entirely out of buttons. She turns 95 on Wednesday, believe it or not!"
    }, 
    {
        name: 'Sarah Bitamazire',
        description: "The older sister of the bride and one of the toastmasters for this evening. She had more ambition as a 4-year old than Esther has had throughout her whole life and knows everything about everything that Esther knows nothing about. A true artist who touches the souls around her with poems and songs, ask her about her recent poetry show in Washington! Do not ask her how much of Esthers bachelor thesis is thanks to her."
    }, 
    {
        name: 'Ruth Bitamazire',
        description: "The younger sister of the bride. Shared a room with Esther throughout childhood and has a Ph.D. in the art of stealing Esther's clothes. Nothing makes Ruth feel better than one (or three) long nap and she loves a good kebab."
    }, 
    {
        name: 'Tomas Morin',
        description: "Ruths partner. Also known as &#39;Storsvetsarn&#39; (Big Welder). Lives in a big house with a big yard where he takes care of four rabbits, a very old cat, and a Ruth."
    }, 
    {
        name: 'Ossian Rehn',
        description: "Older brother of the groom. Is often found in the garden contemplating life and admiring plants, especially the large rhododendron at the summer house in Finland. He's really good at remembering song lyrics."
    }, 
    {
        name: 'Amanda Lindqvist',
        description: "Ossians fiancée. Winner of the Funkis Festival 2018, so obviously she’s a really good singer. Never misses Bingolotto (Swedish TV show) and has managed to persuade Ossian to consider eating candy, which is quite the feat!"
    }, 
    {
        name: 'Carita Rehn',
        description: "The younger sister of the groom and one of our toastmasters this evening. Her childhood dream was to have her very own goat and that everyone would call her 'Sistah-C'. Maybe you guys can help us fulfil that second childhood dream of hers tonight?"
    },
    {
        name: 'Filip Olsok',
        description: 'Caritas fiancé. Knows a lot about everything and speaks an unbelievable number of languages. Learned Swedish a few years ago but recently beat all his Swedish family members (three of whom are teachers) in a trivia game about the Swedish language.'
    },
    {
        name: 'Lennart "Lelle" Einarsson',
        description: "Uncle of the bride. Has such a heavy dialect that he was recorded and used in a study at a university. When native Stockholmers think Esther talks funny, it’s probably his fault."
    },
    {
        name: 'Marie Einarsson',
        description: "Lelles wife. Probably the youngest looking grandmother in the world. Many of Esthers fondest memories from her childhood summers in Ovanåker are thanks to Marie."
    },
    {
        name: 'Julia Eriksson',
        description: "The brides cousin. Once when Julia and Esther were children, a lady approached them in church and told them that they resembled each other so much that it was hard for her to tell who was who. This was, for obvious reasons, a very funny and peculiar thing to say."
    },
    {
        name: 'Fredrik Eriksson',
        description: "Julias fiancé. Has played professional Bandy in several teams. Father to two little boys, one of whom is here with us tonight!"
    },
    {
        name: 'Clara Einarsson',
        description: "Cousin of the bride. Has rescued her cousins from several self-made crisis and is one of the people that the bride believes would manage an apocalypse the best. Unless the apocalypse is snake related of course, in which case things would turn sour pretty quickly."
    },
    {
        name: 'Kristina "Kiki" Norén',
        description: "The groom’s aunt. An artist and a potter who has her own studio in her apartment. Has spent many years in France."
    },
    {
        name: 'Daniel Blanchard',
        description: "Kiki’s husband. A very french man who used to work as a cheese manufacturer."
    },
    {
        name: 'Katarina "Kattis" Pettersson',
        description: "The groom’s aunt. Grandmother to three beautiful little girls who she loves spending time with."
    },
    {
        name: 'Per-Gunnar "PG" Pettersson',
        description: "Married to Kattis. Likes to go sailing and tend to his garden. Has a memory from his bachelor party tattooed on his ankle."
    },
    {
        name: 'Gustaf Mauroy',
        description: "The groom’s eldest cousin. His first job after university was as a CEO, very impressive!"
    },
    {
        name: 'Emma Mauroy',
        description: "Married to Gustaf. Performed an impressive Bollywood dance at her own wedding. Mother to two adorable little girls, Selma and Signe."
    },
    {
        name: 'Astrid Pettersson',
        description: "The groom’s cousin. An active young lady who has played both handball and rugby. Just bought her very own kayak."
    },
    {
        name: 'Morgan Rosén',
        description: "Astrids partner. An outdoorsy young man who rescues hikers in need. He also used to be a traffic school teacher."
    },
    {
        name: 'Harald Blomström',
        description: "the groom’s cousin. Livs with his family in München wher he works as a cloud-engineer. You can often catch him on a pair of skiis with his daughter Maja on his back."
    },
    {
        name: 'Caroline "Carro" Blomström',
        description: "Married to Harald. Has lived in Chile and has a grandfather who owned a shrimp factory on the Swedish west coast."
    },
    {
        name: 'Håkan Rehn',
        description: "the groom’s uncle. If you’re from around Uppsala you’ve probably heard his voice on the radio, where he has worked for more than 20 years."
    },
    {
        name: 'Catarina "Kinna" Rehn',
        description: "Married to Håkan. A remarkable soprano who knows and remembers most things. She also speaks Italian!"
    },
    {
        name: 'John Rehn',
        description: "Cousin of the groom and this weddings official representative from Gen Z. Just graduated (with practically straight A’s) from a well respected high school where he studied music production."
    },
    {
        name: 'Teresia "Tese" Quinn',
        description: "The groom’s aunt. Has lived in England for the past 30 years. She actually attended the same school that Vidar went to in Tanzania, although a few years earlier. A bell ringer in Weston Super Mare."
    },
    {
        name: 'Scott Quinn',
        description: "Cousin of the groom. A film maker who recently edited National Geographics docuseries “Wild Central America with Nigel Marven”."
    },
    {
        name: 'Rosi Valastro',
        description: "Scott’s fiancée. Originally from Sicily but currently lives and works in Venice. Rosi and Scott will be getting married this same weekend next year and we can’t wait to celebrate with them!"
    },
    {
        name: 'Anja Quinn',
        description: "The groom’s cousin. A freelancing designer and illustrator. Anja and her brother Scott together make up a minority of people who speak Finnish-Swedish with a slight British accent."
    },
    {
        name: 'Victor "Vicke" Falck',
        description: "the groom’s uncle. A hobby carpenter and renovation expert. Does a great imitation of the Stockholm accent."
    },
    {
        name: 'Johanna Falck',
        description: "married to Victor. A potter and journalist. Managed to convince Vidar to interview and model for K-Rauta (Finnish hardware store) magazine this summer."
    },
    {
        name: 'Benjamin "Ben" Falck',
        description: "Cousin of the groom. Studied Hotel Management in Åland and now lives in Turku with his fiancée Minna and their cat Ruth. Spent some time in Amsterdam during the same time as Vidar."
    },
    {
        name: 'Minna Hemming',
        description: "Engaged to Ben. A speech therapist from Åland. We’d be surprised if any of our other guests have spent more time at sea than she has!"
    },
    {
        name: 'Sonja Falck',
        description: "Cousin to the bride and mother to the pine-cone-loving dog Börje. Manages marketing for Gumbostrands art gallery outside of Helsingfors."
    },
    {
        name: 'Rasmus Laine',
        description: "Sonjas partner. Former professional alpine skier, these days he manages private equity funds."
    },
    {
        name: 'Jenny Andreasson',
        description: "Bridesmaid. When Esther moved to Sweden, Jenny showed her where in the school yard you could play horses and the two have been friends ever since. Since then, they have spent countless hours painting their nails, giggling at memes and discussing The Sims."
    },
    {
        name: 'Elina Svensson',
        description: "Bridesmaid. Esther&#39;s constant companion since around 2010 and the absolute sorest loser at this wedding. If she’s not already accompanying Esther on one of her stupid ideas, she’s making up her own stupid idea and convincing Esther to come along."
    },
    {
        name: 'Carolin "Carro" Strömgren',
        description: "Bridesmaid. The sensible one in the trio Esther, Elina & Carro. Remembers everything you've ever said and done, for better or worse. Has a very grown up job that Esther doesn’t understand the first thing about."
    },
    {
        name: 'Simon Mases',
        description: "Groomsman. More often than not, Simon is the most handsome person in the room. Is seriously addicted to Coca Cola Zero and knows every single line from Friends and Seinfeld. Has worked in a bank for several years but is soon rebranding himself as image technician."
    },
    {
        name: 'Albin Ydmark Buhre',
        description: "Groomsman. Has worked in some of the finest hotels in Stockholm but still enjoys a dirty meme or two. Has one human baby and one fur baby who both coincidentally happen to dress up as baby Yoda on May the 4th …hmm….."
    },
    {
        name: 'Diana Zambrano',
        description: "Probably the sweetest person you’ll ever meet. Brought said fur baby (see above) all the way from Ecuador and is the biggest Star Wars nerd we know. Maybe she has something to do with the baby Yoda dress up parties…hmm……"
    },
    {
        name: 'Matias "Mati" Kauppinen',
        description: "Groomsman and the person who Vidar has been friends with for the longest time. Has his own company and recruits for the higher ranks at Volvo Cars. Finnish-Eritrean who also speaks sign language. Widely famous for never being on time."
    },
    {
        name: 'Sewit Jacob',
        description: "Mati’s fiancée - Works as a client manager at a media agency in Stockholm. Recently came back from a trip to Italy where she and Matias enjoyed the lovely heat wave."
    },
    {
        name: 'Alain "Blacka" Arakaza',
        description: "The brother the Bitamazire sisters never had. It was through Alain that the bridal couple first met, so if you’re having a good time tonight, you know who to thank!"
    },
    {
        name: 'Hanna Aldén',
        description: "Shared a room with Esther for two years in Sydney. During these years, Hanna’s main roomie duty was to sort out all of Esther’s feelings and kill all the spiders that broke into their home. She was rewarded with bagels, coffee and the promise of eternal friendship."
    },
    {
        name: 'Samuel Aldén',
        description: "Apart from Esther’s siblings, Samuel is the person who Esther has fought the most with. One of their arguments ended with Samuel driving seventeen laps around a roundabout to get Esther to be quiet. Esther, who can get car sick on a straight road, finally gave in (but never forgot…)."
    },
    {
        name: 'Marika Samuelsson & Sara Fritzon',
        description: "this couple needs to be written about together to avoid them starting an argument over who is the real friend of the bride and groom. A very generous couple who everyone loves to visit for several reasons. They always have the best snacks, they have two cute cats and they always have MTV 2000’s hits playing on their enormous TV. Knowing you is such a treat!"
    },
    {
        name: 'Lovisa Hoffman',
        description: "The smartest and kindest person anyone has ever known. The only person who would bring her ATP Atelier purse to the stables she is about to clean out. Do not ask her how much of Esthers bachelor thesis she was involved in. Is moving to Bangladesh next week."
    },
    {
        name: 'Mathilda Stefansson',
        description: "Great at singing, even greater at partying. Mathilda lights up every room she enters. Recently did a flawless imitation of Justin Bieber at Esthers bachelorette."
    },
    {
        name: 'Emilia Abrahamsson',
        description: "Made friends with Esther during the millions of bus trips between Storvreta and Uppsala in 2006. Lives in Los Angeles where she curates a very impressive thrifted closet."
    },
    {
        name: 'Emma Suh',
        description: "A very organized young lady who starts using both hay fever medication and SPF in February. When Esther and Emma were teenagers, dangerous decibel levels were reached when they laughed together. Has competed in international disco dancing competitions."
    },
    {
        name: 'David Suh',
        description: "Married to Emma. Recently graduated law school. Was one of the victims of the dangerous decibel (see above) levels that Emma and Esther produced when they were teenangers. Sorry!!!"
    },
    {
        name: 'Monica Han',
        description: "Was Esther’s housemate in Sydney. Knows everything about coffee and is a musical genius. Taught Esther everything there is to know about tempura, drinking whisky and that an iced americano is the superior coffee order."
    },
    {
        name: 'Emma Grönvik Möller',
        description: "Knows everything about both cooking and eating the best food worldwide. Started out as a fan of Esther’s blog but the two quickly became friends. And no, you can’t read Esther’s old blog."
    },
    {
        name: 'Sandra Jalalian',
        description: "Undefeated champion of the best Fridays and great flowers. Sees the good in everything and everyone except the part of running when she gets tired."
    },
    {
        name: 'Henrik Jalalian',
        description: "The only lawyer in Uppsala with a theatrical background. Loves Djurgården IF almost as much as he loves his wife Sandra."
    },
    {
        name: 'Aina De Guia Eriksson',
        description: "Was the bride and groom’s housemate at one if Stockholms most central addresses. Esther and Ainas wardrobes doubled in size during this time as they discovered that they have the exact same sizes in all clothes and shoes. Score!"
    },
    {
        name: 'Desiree "Dessi" Falk',
        description: "Don’t let her calm exterior fool you, she has lived in the mountains of China where she trained Kung Fu with monks in a temple."
    },
    {
        name: 'Kai Shenton',
        description: "A Swedish Zambian and a real Crocodile Dundee type of guy. Regularly posts videos of the elephants that visit his veranda in South Luangwa National Park. 70% of his diet consists of Biltong (dried meat)."
    },
    {
        name: 'Niya Saran',
        description: "Kais partner and probably Zambias biggest producer and distributor of boba-tea."
    },
    {
        name: 'Omar Anders Eltom',
        description: "Probably the only Sudanese person in the world whose middle name is Anders. Has a Masters degree in Industrial Engineering and is the proud owner of a Tesla."
    },
    {
        name: 'Tor Sinclair',
        description: "To put it simply – Tor isn’t the first person to shy away from a conspiracy theory."
    },
    {
        name: 'Daniel Meneley',
        description: "Originally from Canada but has spent most of his life in Turks and Caicos where he currently lives with his family and manages exclusive holiday villas. Rumour has it that Daniel is the new owner of Prince’s basketball hoop."
    },
    {
        name: 'Priscilla Meneley',
        description: "Married to Daniel. Originally from São Paolo and now lives a stone's throw away from beaches that year after year are ranked the worlds best."
    },
    {
        name: 'Charles Flynn',
        description: "An Englishman who has spent the majority of his life in warmer and sunnier places. Few people have spent as many nights with the groom as Charles has."
    },
    {
        name: 'Niamh McCarthy',
        description: "Originally from Ireland but now lives with Charles in Lisbon. Do not assume you know how to pronounce her name."
    },
    {
        name: 'Anton Lindeborg',
        description: "Owner of Napolyon Bistro, also known as Vidar's second home. Many of the couple's most best evenings have both started and ended with Anton at Napolyon. The bride and groom are thrilled to finally get the chance to serve Anton. Cheers!"
    },
    {
        name: 'Johanna Pihl',
        description: "A dancing queen who loves ice cream and is obsessed with the series Friends. Works as an Impact Media Manager at a media agency in Stockholm."
    },
    {
        name: 'Mats Svensson',
        description: "A golf loving base player who also happens to be interested in old churches around Sweden and Europe. If you want your family researched, a trip planned or have a question about appliances, Mats is your man."
    },
    {
        name: 'Birgitta "Gittan" Svensson',
        description: "You can’t find a person with a better attitude and a more positive spirit than Birgitta. Unless she loses a game of course, in which case she joins her daughter Elina on the list of this wedding’s sorest losers."
    },
    {
        name: 'Joel Danielsson',
        description: "Musician and music teacher. Drummer in the amazing band Kainoabel."
    },
    {
        name: 'Ida Jansson',
        description: "Photographer. Ask her about the shenanigans her neighbours always seem to be up to."
    }
]