import { Routes, Route } from 'react-router-dom'
//CSS
import styled from 'styled-components'
import { colors, flex } from './styles/partials'
//resources
import Flowers from './resources/flowers.webp'
//react hooks
import { useState, useEffect } from 'react'
//context
import AppContext from './context/AppContext'
//database
import { english, swedish } from './db/text'
//components
import Home from './pages/Home'
import Guests from './pages/Guests'
// import Navbar from './components/Navbar'
// import Header from './components/Header'
// import Where from './components/Where'
// import OnTheDay from './components/OnTheDay'
// import Accomodation from './components/Accomodation'
// import Gallery from './components/Gallery'
// import Contact from './components/Contact'
// import Info from './components/Info'

const Wrapper = styled.div`
  ${flex('column', 'center', 'center')};
  width: 100%;
  min-height: 100vh;
  background-color: ${colors.main};
`
const ImageContainer = styled.div`
  ${flex('column', 'flex-end', 'center')}
  position: sticky;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  z-index: 15;
  pointer-events: none;

  img {
    width: clamp(800px, 100%, 1200px);
  }
`

function App() {

  const [language, setLanguage] = useState()
  const [db, setDb] = useState()
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
        window.removeEventListener('scroll', handleScroll);
    }
  }, [])

  useEffect(() => {
    let chosenLanguage = localStorage.getItem('language')
    
    if (chosenLanguage) {
      setLanguage(chosenLanguage)
    } else {
      localStorage.setItem('language', 'english')
      setLanguage('english')
    }
  }, [])

  useEffect(() => {
    if (language === 'english'){
      setDb(english)
    } else if (language === 'swedish'){
      setDb(swedish)
    }
  }, [language])

  useEffect(() => {
    if (db) {
      if (600 > scrollPosition > 0) {
        let flowers = document.querySelector('.flowers')
        flowers.style.bottom = `-${scrollPosition/6}px`
      }
      if (scrollPosition > 100){
        document.querySelector('.logo-in-nav').classList.add('visible')
      } else {
        document.querySelector('.logo-in-nav').classList.remove('visible')
      }
    }
  }, [scrollPosition])

  return (
    <AppContext.Provider value={{language, setLanguage, db, setDb}}>
    <Wrapper>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/guests" element={<Guests />}></Route>
      </Routes>
    </Wrapper>
    </AppContext.Provider>
  );
}

export default App;
