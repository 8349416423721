//CSS
import styled from 'styled-components'
import { flex, fonts } from '../styles/partials'
import { Title, FullPageSection } from '../styles/styled-components'
//context
import AppContext from '../context/AppContext'
import { useContext } from 'react'

const TextContainer = styled.div`
    ${flex('column', 'flex-start', 'center')};
    gap: 20px;
    width: 100%;
    padding: 20px;
    text-align: center;

    p {
        font-weight: 400;
        font-size: ${fonts.paragraph};
    }
`

const Where = () => {
    
    const {db, language} = useContext(AppContext)

    return (

        <FullPageSection id='where'>
            {db && (
            <>
            <Title>{db.where_and_when.title}</Title>
            <TextContainer>
                <p>{db.where_and_when.intro}</p>
                <p>{db.where_and_when.ceremony}</p>
                <p>{db.where_and_when.reception}</p>
            </TextContainer>
            </>
            )}
        </FullPageSection>
    )
}

export default Where
