import { Title, FullPageSection, IntroText } from '../styles/styled-components'
//context
import AppContext from '../context/AppContext'
import { useContext } from 'react'

const Accomodation = () => {

    const {db, language} = useContext(AppContext)

    return (
        <FullPageSection id='accomodation'>
            {db && (
                <>
                <Title>{db.accomodation.title}</Title>
                <IntroText>{db.accomodation.first} <a href="https://officersvillorna.sirvoy.me/" target='_blank'>{language === 'swedish' ? 'här.' : 'here.'}</a></IntroText>
                <IntroText>{db.accomodation.second}</IntroText>        
                <IntroText>{db.accomodation.third}</IntroText>
                </>
            )}
        </FullPageSection>
    )
}

export default Accomodation