export const guestsSwedish = [
// familj
{
    name: 'Elsie-Marie Einarsson',
    description: 'Brudens mor. Har sitt ursprung i Hälsingland men hennes hjärta tillhör Östafrika där hon levt stora delar av sitt liv. Om du är sugen på en safari hjälper hon mer än gärna till att organisera resan!'
},{
    name: 'Julius Bitamazire',
    description: 'Brudens far. Påstår än vid 64-års ålder att han är 40. Dricker aldrig kallt vatten och hävdar bestämt att ketchupens främsta funktion är att kyla ner maten. '
},{
    name: 'Agnes Bitamazire',
    description: 'Fru till brudens far. En glädjespridare som dansade loss på Facetime då vi ringde och berättade att vi hade förlovat oss. Vi hoppas få skymta mer av dessa danssteg senare ikväll!'
},{
    name: 'Jon-Erik Rehn',
    description: 'Far till brudgummen. Gjorde högskoleprovet för några år sedan, enbart för nöjets skull. Resultatet blev tre felaktiga svar, tills det visade sig att det i själva verket var facit som det var fel på och inte Jon-Erik. Så ja, honom kan ni fråga om precis vad som helst! Kör i vind!'
},{
    name: 'Harriet Rehn',
    description: 'Mor till brudgummen. Reste till Tanzania första gången när hon var sju år och har sedan dess varit allergisk mot det nordiska vinterhalvåret. En fena på både vardag, fest och att inseminera kossor. '
},{
    name: 'Inger Söder',
    description: "Brudens mormor. Det är vetenskapligt bevisat att det är omöjligt att lämna hennes hem utan att ha fått i sig en hel bricka fika samt ett par vävda linnehanddukar."
},{
    name: 'Ellert Söder',
    description: "Gifte sig i smyg med brudens mormor för 13 år sedan och fick då 16 barnbarn på köpet."
},{
    name: 'Mea Rehn',
    description: 'Brudgummens farmor och Rehn-familjens självklara matriark. Har en egen smyckeskollektion. Tro det eller ej men hon fyller 95 på onsdag.'
},{
    name: 'Sarah Bitamazire',
    description: 'Brudens storasyster och toastmaster ikväll. Hade mer ambition som 4-åring än vad Esther haft sammanlagt i hela sitt liv och kan allt om allt som Esther inte kan något om. En äkta estet som berör alla i sin närhet med både sång och dikt, fråga henne gärna om hennes senaste poesishow i Washington. Fråga henne inte om hur stora delar av Esthers C-uppsats hon stod för.'
},{
    name: 'Ruth Bitamazire',
    description: 'Brudens lillasyster. Delade rum med Esther under hela barndomen och har doktorerat i konsten att sno Esthers kläder. Ruth mår allra bäst efter en (eller tre) långa tupplurer och älskar en go’ kebabtallrik.'
},{
    name: 'Tomas Morin',
    description: 'Ruths sambo. Även känd som Storsvetsarn. Bor på en gård där han sköter om fyra kaniner, en jättegammal katt och en Ruth. '
},{
    name: 'Ossian Rehn',
    description: 'Brudgummens storebror. Hittas ofta i trädgården där han filosoferar och beundrar växter, allra helst den stora rhododendronen på sommarstället i Finland. Är riktigt skicklig på att minnas sångtexter.'
},{
    name: 'Amanda Lindqvist',
    description: 'Förlovad med Ossian. Vinnare av Funkisfestivalen 2018, alltså en riktig sångfågel. Missar aldrig Bingolotto och har lyckats med den fantastiska bedriften att få Ossian att överväga att äta godis.'
},{
    name: 'Carita Rehn',
    description: 'Brudgummens lillasyster och toastmaster ikväll. Hennes barndomsdröm var att ha en egen get och bli kallad Sistah C. Den sistnämnda drömmen kanske ni gäster kan hjälpa henne att besanna ikväll?'
},{
    name: 'Filip Olsok',
    description: 'Förlovad med Carita. Kan mycket om det mesta och talar oförskämt många språk. Lärde sig svenska för några år sedan men spöade nyligen samtliga familjemedlemmar (varav tre är lärare) i frågespel om det svenska språket.'
},{
    name: 'Lennart "Lelle" Einarsson',
    description: "Morbror till bruden. Pratar så bred Ovanåkersmål att han spelades in och blev föremål för vidare studier. När Stockholmare tycker att Esther pratar konstigt så är det nog ofta hans fel."
},{
    name: 'Marie Einarsson',
    description: "Gift med Lelle. Världens absolut fräschaste mormor. Spelade en stor roll i att Esther har så fina minnen från barndomens somrar i Ovanåker."
},{
    name: 'Julia Einarsson',
    description: "Kusin till bruden. En gång när Julia och Esther var små kom en tant i kyrkan fram till dem och sa att de minsann var så lika varandra att det knappt gick att se vem som var vem. Detta var av uppenbara skäl ett väldigt roligt påstående."
},{
    name: 'Fredrik Eriksson',
    description: "Förlovad med Julia. Spelat bandy i Elitserien. Tvåbarnsfarsa."
},{
    name: 'Clara Einarsson',
    description: "Kusin till bruden. Har räddat sina kusiner ur många självförvållade kriser och är än idag en utav de människor som bruden tror hade klarat sig bäst i en apokalyps. Om inte apokalypsen är ormrelaterad då såklart, då skiter det sig nog rätt snabbt för Clara."
},{
    name: 'Kristina "Kiki" Norén',
    description: 'Faster till brudgummen. Målare och keramiker med egen ateljé i hemmet. Bott många år i Frankrike.'
},{
    name: 'Daniel Blanchard',
    description: 'Gift med Kiki. Fransos och föredetta osttillverkare.'
},{
    name: 'Katarina "Kattis" Pettersson',
    description: 'Brudgummens faster. Farmor till tre underbara små flickor som hon gärna ser så ofta som möjligt.'
},{
    name: 'Per-Gunnar "PG" Pettersson',
    description: 'Gift med Kattis. Gillar att segla och att sköta trädgården. Ett minne från hans egna svensexa finns på ena vristen i form av en tatuering.'
},{
    name: 'Gustaf Mauroy',
    description: 'Brudgummens äldsta kusin. Gick direkt från studier till VD-stol'
},{
    name: 'Emma Mauroy',
    description: 'Gift med Gustaf. Har dansat Bollywood-dans. Mamma till två otroligt söta döttrar, Selma och Signe.'
},{
    name: 'Astrid Pettersson',
    description: 'Kusin till brudgummen. Sportig tjej med både handboll och rugby i bagaget. Nybliven kajakägare.'
},{
    name: 'Morgan Rosén',
    description: 'Sambo till Astrid. Friluftsmänniska, fjällräddare och föredetta trafiklärare.'
},{
    name: 'Harald Blomström',
    description: 'Kusin till brudgummen. Bor tillsammans med sin familj i München, där han jobbar som moln-ingenjör. Ses ofta på skidor med dottern Maja på ryggen.'
},{
    name: 'Caroline "Carro" Blomström',
    description: 'Gift med Harald. Har bott i Chile och har en farfar som ägde en räkfabrik på västkusten.'
},{
    name: 'Håkan Rehn',
    description: 'Farbror till brudgummen. Kommer du från Uppsalatrakten så är det mycket möjligt att ni hört Håkans röst någon gång. Rapporterar sedan mer än 20 år tillbaka i radio från Gunillaklockan i Uppsala på Valborgskvällen.'
},{
    name: 'Catarina "Kinna" Rehn',
    description: "Gift med Håkan och mamma till John och Felix. Tonsäker sopran som kan och minns det mesta. Pratar italienska."
},{
    name: 'John Rehn',
    description: 'Kusin till brudgummen och en av kvällens representanter från generation Z. Tog precis studenten från Rytmus musikgymnasium. Storebrorsa till Felix som just nu är i Sydkorea på Scoutläger.'
},{
    name: 'Teresia "Tese" Quinn',
    description: 'Moster till brudgummen. Bor sedan 30 år tillbaka i England. Faktiskt gått på samma skola som Vidar i Tanzania (dock ett antal år tidigare). Klockringaren i Weston Super Mare.'
},{
    name: 'Scott Quinn',
    description: 'Kusin till brudgummen. Filmskapare. Nyligen redigerat National Geographics dokumentärserie "Wild Central America with Nigel Marven".'
},{
    name: 'Rosi Valastro',
    description: 'Från Sicilien men bor och jobbar i Venedig. Gifter sig med Scott samma helg som denna fast nästa år.'
},{
    name: 'Anja Quinn',
    description: 'Kusin till brudgummen. Frilansande designer och illustratör. Tillsammans med sin bror så utgör hon den minoritet av världen som pratar finlandssvenska med brittisk dialekt.'
},{
    name: 'Victor "Vicke" Falck',
    description: 'Brudgummens morbror. Hobbysnickare och renoveringsexpert. Be honom gärna imitera en Stockholmare'
},{
    name: 'Johanna Falck',
    description: 'Gift med Victor. Kan det mesta om design. Keramiker samt journalist. Fick i somras Vidar att ställa upp som modell för en artikel i K-rauta magazine.'
},{
    name: 'Benjamin "Ben" Falck',
    description: 'Kusin till brudgummen. Pluggat Hotel Management på Åland men är just nu bosatt i Åbo med sin fästmö Minna och katten Ruth. Bodde samtidigt som Vidar i Amsterdam.'
},{
    name: 'Minna Hemming',
    description: 'Förlovad (sedan i våras) med Ben. Logoped och ålänning. Om det finns någon här ikväll som spenderat mer tid till sjöss så hade vi blivit förvånade.'
},{
    name: 'Sonja Falck',
    description: 'Kusin till brudgummen och mamma den kott-besatta hunden Börje. Hanterar marknadsföring för konstgalleriet Gumbostrand Konst & Form utanför Helsingfors.'
},{
    name: 'Rasmus Laine',
    description: 'Partner till Sonja. Tidigare professionell alpin skidåkare som nuförtiden förvaltar fonder.'
}
// vänner
,{
    name: 'Jenny Andreasson',
    description: 'Brudtärna. När Esther flyttade till Sverige så visade Jenny henne var på skolgården man kunde leka häst och sedan dess har de varit kompisar. Sedan dess har de två spenderat otaliga timmar med att måla naglarna, fnissa åt memes och diskutera The Sims. '
},{
    name: 'Elina Svensson',
    description: 'Brudtärna. Esthers ständiga följeslagare sedan ca 2010 och den absolut sämsta förloraren på detta bröllop. Om hon inte gör Esther sällskap på något av hennes upptåg så planerar hon ett eget upptåg som hon kommer övertala Esther att var med på. Fråga henne gärna var lavendeln på borden är från.'
},{
    name: 'Carolin "Carro" Strömgren',
    description: 'Brudtärna. Den förnuftiga i trion Esther, Elina & Carro. Minns allt man någonsin sagt och gjort, på gott och ont. Har ett väldigt vuxet jobb som Esther inte förstår sig på.'
},{
    name: 'Simon Mases',
    description: 'Marskalk. I regel alltid stiligaste personen i rummet. Har ett gravt Coca Cola Zero missbruk och kan varenda replik från Vänner och Seinfeld.  Efter att ha jobbat länge inom bank så ska han i höst skola om sig till bildtekniker.'
},{
    name: 'Albin Ydmark Buhre',
    description: 'Marskalk. Har jobbat på många av Stockholms finaste hotell, men trots detta lyckats behålla sin inte så rumsrena humor. Liverpoolsupporter men trots det en riktigt fin kille.'
},{
    name: 'Diana Zambrano',
    description: 'Gift med Albin och tillsammans har de snart ett-åriga Lucas. Flyttade till Sverige från Ecuador för ca 5 år sedan och tog med sin hund. Star-Wars nörd och antagligen världens snällaste människa.'
},{
    name: 'Matias "Mati" Kauppinen',
    description: 'Marskalk och den person som Vidar varit vän med längst. Driver eget företag samt sköter rekryteringen av högt uppsatta på Volvo Cars. Finsk-Eritrean som även pratar teckenspråk. Känd för att vara duktig på att passa tider (eller inte alls).'
},{
    name: 'Sewit Jacob',
    description: 'Nyförlovad med Matias. Jobbar som Client Manager på mediebyrå i Stockholm. Kom precis tillbaka från en resa i Italien tillsammans med Matias där de kunde njuta av kyliga 45 grader.'
},{
    name: 'Alain "Blacka" Arakaza',
    description: 'Brodern som systrarna Bitamazire aldrig fick.  Det var genom Alain som brudparet träffades för första gången så han ska ha tack för hela denna fest! '
},{
    name: 'Hanna Aldén',
    description: "Gift med Samuel och vän till brudparet. Delade rum med bruden i två år under tiden de bodde i Sydney. Under dessa år hade Hanna huvudansvar för att reda ut alla Esthers känslor samt döda alla spindlar som inkräktade på huset. Hon fick betalt i bagels, kaffe och löftet om evig vänskap."
},{
    name: 'Samuel Aldén',
    description: 'Den person utöver Esthers syskon som hon bråkat mest med i sitt liv. Ett av dessa bråk slutade med att Samuel körde sjutton varv runt en rondell för att få Esther att sluta tjafsa. Esther, som kan bli åksjuk på en helt rak väg, fick så hon teg (tillslut). '
},{
    name: 'Sara Fritzon & Marika Samuelsson',
    description: "Detta par måste skrivas ihop då de annars kommer tjafsa om vem som är den riktiga gästen på bröllopet. Ett riktigt bjussigt och generöst par som man gärna är hemma hos av flera anledningar. De har alltid godast snacks, två skitgulliga katter och MTV 2000’s Hits spelas alltid i bakgrunden. Ja ni hör ju. Det är en fröjd att känna dem!"
},{
    name: 'Lovisa Hofmann',
    description: "Den smartaste och snällaste vi känner. Den enda personen som atr med sin ATP Atelierväska när hon ska mocka i stallet. Även hon bör ej tillfrågas hur stor del av Esthers C-uppsats som hon stod för. Flyttar till Bangladesh nästa vecka."
},{
    name: 'Mathilda Stefansson',
    description: 'Skönsjungande partypingla som livar upp varje rum hon kommer in i. Gjorde nyligen en perfekt imitation av Justin Bieber på Esthers möhippa.'
},{
    name: 'Emilia Abrahamsson',
    description: 'Blev vän med Esther under de otaliga bussresorna mellan Uppsala och Storvreta anno 2006. Är bosatt i Los Angeles sedan 12 år tillbaka där hon kurerar en imponerande secondhandfyndad garderob.'
},{
    name: 'Emma Suh',
    description: 'En väldigt ordentlig tjej som börjar med pollentabletter och SPF redan i Februari. När Esther och Emma var tonåringar så uppmättes skadliga decibelnivåer när de skrattade tillsammans. Har tävlat internationellt i Disco-dans.'
},{
    name: 'David Suh',
    description: 'Gift med Emma och har nyligen avslutat juristprogrammet. En av de stackars personerna som drabbades hårdast av de skadliga decibelnivåerna som uppmättes när Esther och Emma skrattade (se ovan).'
},{
    name: 'Monica Han',
    description: 'Bodde tillsammans med bruden i Sydney. Kaffe-konnässör och musikalist geni. Lärde Esther att äta tempura, dricka whisky och att en iced-americano visst är den bäste kaffebeställningen.'
},{
    name: 'Emma Grönvik Möller',
    description: 'Vän till brudparet. Kan allt om att både laga och äta den godaste maten var i världen hon än befinner sig. Var till en början en trogen bloggläsare av Esthers blogg. De blev vänner efter att Esther skrev följande citat på bloggen: ”Love you yall!! Speciellt Emma Grönvik Möller, shout out till dig för du är inte ens min kompis du är bara ett fan. Eller vi kan vara kompisar om du vill. Hej.” P.S. Nej – ni får inte läsa Esthers gamla blogg.'
},{
    name: 'Sandra Jalalian',
    description: 'Oslagbar på fredagsmys, vardagslyx och bra buketter. Ser det goda i allt och alla med undantag för den delen av löpturen då hon behöver använda sitt pannben. '
},{
    name: 'Henrik Jalalian',
    description: 'Den enda juristen i Uppsala med teaterbakrund. Älskar Djurgårdens IF nästan lika mycket som han älskar Sandra.'
},{
    name: 'Aina De Guia Eriksson',
    description: "Vän till brudparet och bodde tillsammans med dem i ett kollektiv på Stockholms mest centrala adress. Där utvidgades både Aina och Esthers garderober då de upptäckte att de har exakt samma storlek på exakt alla kläder och skor. Score!"
},{
    name: 'Desiree "Dessi" Falk',
    description: 'Låt er inte luras av hennes av hennes lugna fasad, hon har bott i bergen i Kina där hon tränade Kung-fu med munkar i ett kloster.'
},{
    name: 'Kai Shenton',
    description: 'Svensk-Zambier och en riktig Crocodile Dundee-typ. Lägger regelbundet upp videos på elefanterna som besöker hans veranda i South Luangwa National Park. 70% av hans diet består av Biltong (torkat kött).'
},{
    name: 'Niya Saran',
    description: 'Partner till Kai och Zambias förmodligen största producent/leverantör av boba-tea.'
},{
    name: 'Omar Anders Eltom',
    description: 'Förmodligen den enda Sudanesen som heter Anders i andra namn. Har en Masters-degree i Industrial Engineering från KTH. Stolt Tesla-ägare.'
},{
    name: 'Tor Sinclair',
    description: 'Låt oss säga såhär: Tor är inte den som drar sig undan konspirationsteorier.'
},{
    name: 'Daniel Meneley',
    description: "Ursprungligen Kanadensare men levt stor del av sitt liv och är nu bosatt med sin familj på Turks & Caicos där han hyr ut villor till världens kändis-elit. Ägare av Prince's gamla basket-korg."
},{
    name: 'Priscila Meneley',
    description: 'Gift med Daniel och mamma till deras lilla son Aidan. Ursprungligen från São Paulo och bor idag ett stenkast från vad som år efter år rankas som världens bästa strander.'
},{
    name: 'Charles Flynn',
    description: 'Engelsman som spenderat största delen av sitt liv på betydligt varmare breddgrader. Det finns få personer som spenderat lika många nätter med brudgummen.'
},{
    name: 'Niamh McCarthy',
    description: 'Ursprungligen från Irland men bor numera tillsammans med Charles i Lissabon. Svenskar: Fråga gärna innan ni antar hur hennes namn uttalas.'
},{
    name: 'Anton Lindeborg',
    description: 'Pluggade i Spanien samtidigt som Vidar och är numera ägare till bland annat Napolyon Bistro, även känt som Vidars andra hem. Många av brudparets trevligaste kvällar har både börjat och slutat med Anton på Napolyon. Brudparet är superglada att äntligen få tillfälle att bjuda tillbaka. Skål!'
},{
    name: 'Johanna Pihl',
    description: 'Partner till Anton. En dancing-queen som älskar glass och är besatt av tv-serien Vänner. Jobbar som Impact Media Manager på en mediebyrå i Stockholm.'
},{
    name: 'Mats Svensson',
    description: 'Golfspelande basist med ett intresse för gamla kyrkor i Sverige och i Europa. Om man vill ha sin släkt forskad på, ha en reseplanerare eller lära sig allt om vitvaror så är Mats rätt person att fråga.'
},{
    name: 'Birgitta "Gittan" Svensson',
    description: 'Svårt att hitta en person med bättre attityd och en mer positiv livssyn än Birgitta. Om hon inte förlorar på spel förstås, då kan det hända att hon går om sin dotter Elina och tar första plats på listan över detta bröllops sämsta förlorare.'
},{
    name: 'Joel Danielsson',
    description: 'Musiker och musiklärare. Trummis i det fantastiska bandet Kainoabel.'
},{
    name: 'Ida Jansson',
    description: 'Fotograf. Fråga henne gärna om de galenskaper som hennes grannar ställer med i tid och otid. '
}]