//CSS
import styled from 'styled-components'
import { colors, flex, fonts } from '../styles/partials'
//resources
import Names from '../resources/names.webp'
import Animation from '../resources/name-animation.gif'
//react hooks
import { useEffect, useState } from 'react'
//context
import { useContext } from 'react';
import AppContext from '../context/AppContext'

const Container = styled.header`
    ${flex('column', 'flex-start', 'center')};
    gap: 20px;
    min-height: 80vh;
`
const ImageContainer = styled.div`
    max-width: 600px;
    margin-top: 100px;
    pointer-events: none;

  img {
    max-height: 100%;
    max-width: 100%;
    transform: rotate(1deg);
  }
`
const Text = styled.h2`
    font-weight: 300;
    font-size: ${fonts.heading};
    color: ${colors.secondary};
`
const RSVPLink = styled.a`
    border-radius: 4px;
    text-decoration: none;
    font-size: ${fonts.paragraph};
    background-color: ${colors.green};
    color: white;
    padding: 10px 40px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

    &:hover {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }
`

const Header = () => {

    const {language} = useContext(AppContext)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2600);
    })

    return (
        <Container id='home'>
            <ImageContainer>
                <img src={loading ? Animation : Names} />
            </ImageContainer>
            {language && (
                <>
                <Text>{language === 'english' ? '5 august 2023, Älvkarleby' : '5 augusti 2023, Älvkarleby'}</Text>
                {/* <RSVPLink href={'#contact'}>{language === 'english' ? 'RSVP' : 'OSA'}</RSVPLink> */}
                </>
            )}
        </Container>
    )
}

export default Header