//CSS
import styled from 'styled-components'
import { colors, flex } from '../styles/partials'
//resources
import Names from '../resources/names.webp'
import SwedenFlag from '../resources/sweden.png'
import UKFlag from '../resources/uk.png'
//context
import AppContext from '../context/AppContext'
import { useContext, useState } from 'react'

const Container = styled.nav`
    ${flex('row', 'flex-end', 'center')}
    position: fixed;
    top: 0;
    background-color: ${colors.main};
    width: 100%;
    z-index: 10;
    padding: 20px;
`
const ImageContainer = styled.a`
    width: 160px;
    opacity: 0;
    position: absolute;
    left: 20px;
    transition: .5s linear;

    &.guests-page {
        opacity: 1;
    }

    img {
        max-height: 100%;
        max-width: 100%;
    }

    &.visible {
        opacity: 1;
    }
`
const NavLinks = styled.ul`
    ${flex('row', 'flex-start', 'center')};
    gap: 16px;
    margin-right: 20px;

    a {
        text-decoration: none;
        color: ${colors.secondary};
        font-size: 16px;

        &:hover {
            text-decoration: underline;
        }
    }

    select {
        font-size: 16px;
    }

    @media (max-width: 950px) {
      display: none;
    }
`
const Hamburger = styled.div`
    min-height: 27px;
    min-width: 40px;
    ${flex('column', 'center', 'center')};
    gap: 6px;
    cursor: pointer;

    @media (min-width: 950px) {
      display: none;
    }

    >span {
        height: 5px;
        width: 40px;
        background-color: ${colors.secondary};
        transition: .2s linear;
        pointer-events: none;
    }

    &.active {

        span:nth-child(1){
            transform: translateY(6px) rotate(45deg);
        }
        span:nth-child(2){
            display:none;
        }
        span:nth-child(3){
            transform: translateY(-6px) rotate(-45deg);
        }
    }
`
const Menu = styled.ul`
    position: fixed;
    display: none;
    top: 60px;
    right: 0;
    height: 100vh;
    width: 100vw;
    background-color: ${colors.main};

    &.active {
        ${flex('column', 'flex-start','center')};
    }

    a {
        margin-bottom: 40px;
        text-decoration: none;
        color: ${colors.secondary};
        font-size: 20px;

        &:hover {
            text-decoration: underline;
        }
    }

    select {
        margin-top: 40px;
        font-size: 20px;
    }
`

const Navbar = ({guestsPage}) => {

    const {language, setLanguage, db} = useContext(AppContext)

    const openHamburger = (e) => {
        document.querySelector('.hamburger').classList.toggle('active')
        document.querySelector('.menu').classList.toggle('active')
    }

    const handleLanguageChange = (e) => {
        setLanguage(e.target.value)
        localStorage.setItem('language', e.target.value)
    }

    return (

        <Container>
            {db && (
            <>
            <ImageContainer href={!guestsPage ? '#home' : '/'} className={!guestsPage ? 'logo-in-nav' : 'logo-in-nav guests-page'}>
                <img src={Names} alt="" />
            </ImageContainer>
                {!guestsPage && (
                <NavLinks>
                    <a href="#where">{db.where_and_when.title}</a>
                    <a href="#on-the-day">{db.on_the_day.title}</a>
                    <a href="#accomodation">{db.accomodation.title}</a>
                    <a href='#information'>{db.info.title}</a>
                    {/* <a href="#contact">{db.contact.title}</a> */}
                    <Flag src={language === 'swedish' ? SwedenFlag : UKFlag} alt='language' />
                    <select onChange={(e) => handleLanguageChange(e)} defaultValue={language === 'english' ? 'english' : 'swedish'}>
                        <option value="english">English</option>
                        <option value="swedish">Svenska</option>
                    </select>
                </NavLinks>
                )}
                {!guestsPage && (
                <Hamburger className='hamburger' onClick={(e) => openHamburger(e)}>
                    <span /><span /><span />
                </Hamburger>
                )}
                {!guestsPage ? (
                    <Menu className='menu'>
                        <a href="#where" onClick={() => openHamburger()}>{db.where_and_when.title}</a>
                        <a href="#on-the-day" onClick={() => openHamburger()}>{db.on_the_day.title}</a>
                        <a href="#accomodation" onClick={() => openHamburger()}>{db.accomodation.title}</a>
                        <a href='#information'>{db.info.title}</a>
                        {/* <a href="#contact" onClick={() => openHamburger()}>{db.contact.title}</a> */}
                        <Flag src={language === 'swedish' ? SwedenFlag : UKFlag} alt='language' />
                        <select onChange={(e) => handleLanguageChange(e)} defaultValue={language === 'english' ? 'english' : 'swedish'}>
                            <option value="english">English</option>
                            <option value="swedish">Svenska</option>
                        </select>
                    </Menu>
                ) : (
                    <FlagContainer>
                    <Flag src={language === 'swedish' ? SwedenFlag : UKFlag} alt='language' />
                    <select onChange={(e) => handleLanguageChange(e)} defaultValue={language === 'english' ? 'english' : 'swedish'}>
                        <option value="english">English</option>
                        <option value="swedish">Svenska</option>
                    </select>
                    </FlagContainer>
                )}
            </>
            )}
        </Container>
    )
}

const FlagContainer = styled.div`
    ${flex('row', 'flex-start', 'center')};
    gap: 8px;
`
const Flag = styled.img`
    width: 32px;
`

export default Navbar